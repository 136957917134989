<template>
  <tr>
    <td class="td-product">{{ item.productFullName }}</td>
    <td class="td-product text-center td-bar-code">
      <span>{{ item.barCode }}</span>
    </td>
    <td class="text-center td-quantity">
      <span v-if="!item.allowWithoutPatrimony && isProductAndEquipament(item.typeProduct)">
        {{ item.quantity }}
      </span>
      <IncrementDecrement v-if="item.allowWithoutPatrimony && isProductAndEquipament(item.typeProduct)"
        :min="item.typeProduct == 5 ? 0 : 1" :max="verifyMax" v-model="item.quantity" />
      <div v-if="!isProductAndEquipament(item.typeProduct)">
        <i v-if="item.typeProduct == 3" class="fas fa-people-carry"></i>
      </div>
    </td>
    <td class="text-right">
      <Button :_key="item.id + 'p'" type="danger" classIcon="fa-solid fa-trash" size="small" :params="item"
        :clicked="remove" />
    </td>
  </tr>
</template>
<script>
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import IncrementDecrement from "@nixweb/nixloc-ui/src/component/forms/IncrementDecrement.vue";

import { mapState, mapMutations, mapGetters } from "vuex";

export default {
  name: "ProductSelected",
  components: {
    Button,
    IncrementDecrement,
  },
  props: {
    item: Object,
  },
  computed: {
    ...mapState("generateMoviment", ["type", "productSelected"]),
    ...mapState("user", ["userLogged"]),
    ...mapGetters("generateMoviment", ["quantityMax"]),
    manageByPatrimony() {
      return this.userLogged.parameterStock.manageByPatrimony;
    },
    verifyMax() {
      if (this.type != "output") {
        return this.quantityMax(this.item);
      }
      return null;
    },

  },
  methods: {
    ...mapMutations("generateMoviment", ["removeProductSelected"]),
    isProductAndEquipament(typeProduct) {
      if (typeProduct == 1 || typeProduct == 2 || typeProduct == 5) return true;
      return false;
    },
    remove(item) {
      this.removeProductSelected(item);
    },

  },
};
</script>
<style scoped>
.td-quantity {
  width: 130px;
}

.div-title {
  margin-bottom: 10px;
}

.title {
  font-size: 15px;
}
</style>