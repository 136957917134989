<template>
  <div>
    <br />
    <SuccessMoviment v-if="successMoviment" :rentId="rentId" :movimentId="movimentId"
      msgSuccess="Renovação processada com sucesso!" />
    <div v-if="!successMoviment">
      <div class="text-center" v-show="!loading">
        <span class="check">
          <i class="fa-solid fa-clipboard-check"></i>
          Renovação
        </span>
      </div>
      <br />
      <div v-show="loading">
        <Loading type="line" :center="true" />
        <div class="title">Aguarde, estamos preparando a renovação...</div>
      </div>
      <div v-show="!loading">
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="12" xl="6">
            <Molded>
              <div class="period">Período da Locação</div>
              <div class="period">{{ content.periodRentCurrent }}</div>
            </Molded>
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="12" xl="6">
            <Molded>
              <div class="period side-by-side">Período da Renovação</div>
              <div class="side-by-side div-update">
                <span class="edit" @click="confirmRenewed"> Editar </span>
              </div>

              <div class="period">{{ content.periodRentRenewed }}</div>
            </Molded>
          </b-col>
        </b-row>
        <div class="div-recalculate">
          <Molded v-if="hasRule('Rental') || hasRule('PDV')">
            <div>
              <b-row>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                  <RadioGroup title="Recalcular Valor do Produto?" field="periodRenewedRecalculate" :changed="recalculate"
                    :options="[
                      { text: 'Sim', value: true },
                      { text: 'Não', value: false },
                    ]" v-model="periodRenewedRecalculate" />
                </b-col>
              </b-row>
            </div>
          </Molded>
        </div>
        <div class="div-listproducts">
          <Molded v-if="!loading">
            <TableTotalRecords :totalRecords="content.items.length" />
            <ScrollBar :minHeight="0" :maxHeight="600">
              <table class="table table-responsive-xs">
                <thead>
                  <tr>
                    <th>
                      <span class="title-header">Produto</span>
                    </th>
                    <th>
                      <span class="title-header">Descrição</span>
                    </th>
                    <th class="text-center">
                      <span class="title-header">Locação</span>
                    </th>
                    <th class="text-center">
                      <span class="title-header">Renovação</span>
                    </th>
                    <th class="text-center">
                      <span class="title-header">Status</span>
                    </th>
                    <!-- <th class="text-center" v-if="hasRule('Rental') || hasRule('PDV')">
                      <span class="title-header">Total</span>
                    </th> -->
                  </tr>
                </thead>
                <tbody v-for="item in content.items">
                  <tr>
                    <td class="td-product">{{ item.productFullName }}</td>
                    <td>
                      <div class="td-description">
                        <InputTextEdit v-model="item.description" />
                      </div>
                    </td>
                    <td class="text-center td-quantity">{{ item.totalRent }}</td>
                    <td class="text-center td-quantity">
                      {{ item.totalSelected }}
                    </td>
                    <td class="text-center td-quantity">
                      <span class="status-danger" v-if="!item.isValid">
                        <i class="fa-solid fa-do-not-enter"></i>
                      </span>

                      <span class="status-warning" v-if="item.totalSelected < item.totalRent && item.isValid">
                        <i class="fa-solid fa-triangle-exclamation"></i>
                      </span>
                      <span class="status-success" v-if="item.totalSelected == item.totalRent && item.isValid
                        ">
                        <i class="fa-solid fa-circle-check"></i>
                      </span>
                    </td>
                    <!-- <td class="text-center value" v-if="hasRule('Rental') || hasRule('PDV')">
                      <span>{{ item.totalValue | currency }}</span>
                    </td> -->
                  </tr>
                  <td class="div-patrimony" colspan="8" v-if="item.patrimony.length > 0">
                    <div v-for="patrimony in item.patrimony">
                      <span>
                        <i class="fa-solid fa-circle-arrow-right"></i>
                        {{ patrimony.barCode }}
                      </span>
                    </div>
                  </td>
                </tbody>
                <tbody v-show="content.items.length == 0">
                  <tr>
                    <td colspan="12">
                      <span>Nenhum produto encontrado!</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </ScrollBar>
          </Molded>
        </div>
      </div>
      <br />
      <div class="text-center">
        <span v-show="!loading">
          <slot></slot>
        </span>
        <Button v-if="!loading" _key="btnGenerateOutput" type="success" :disabled="isInvalid" title="Gerar Renovação"
          classIcon="fa-solid fa-circle-check" size="large" :clicked="generate" />
      </div>
    </div>
    <Modal title="Data Fim" :width="500" v-show="showModal('editRenewed')">
      <DateTime title="Fim" field="endDateHourReturn" format="DD/MM/YYYY HH:mm" type="dateTime"
        v-model="endDateHourReturn" />
      <div class="text-right">
        <Button _key="btnEndDateHourRenewed" type="primary" title="Aplicar" classIcon="fa-solid fa-circle-check"
          size="small" :clicked="editRenewed" />
      </div>
    </Modal>
    <Modal title="Produtos" :width="1100" v-show="showModal('ItemsRentForRecalculatePrice')">
      <ItemsRentForRecalculatePrice :item="itemSelected" />
    </Modal>
  </div>
</template>

<script>

import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";
import InputTextEdit from "@nixweb/nixloc-ui/src/component/forms/InputTextEdit.vue";
import TableTotalRecords from "@nixweb/nixloc-ui/src/component/shared/TableTotalRecords.vue";
import Confirmation from "@nixweb/nixloc-ui/src/component/shared/Confirmation.vue";
import DateTime from "@nixweb/nixloc-ui/src/component/forms/DateTime";
import RadioGroup from "@nixweb/nixloc-ui/src/component/forms/RadioGroup.vue";

import ItemsRentForRecalculatePrice from '../ItemsRentForRecalculatePrice.vue'
import DisplayCalculatePeriod from "@nixweb/nixloc-ui/src/component/rental/DisplayCalculatePeriod";
import SuccessMoviment from "../SuccessMoviment.vue";

import { mapState, mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "CheckRenewed",
  components: {
    Molded,
    Button,
    ScrollBar,
    Loading,
    Modal,
    Alert,
    InputTextEdit,
    DisplayCalculatePeriod,
    TableTotalRecords,
    SuccessMoviment,
    Confirmation,
    DateTime,
    RadioGroup,
    ItemsRentForRecalculatePrice
  },
  props: {
    rentId: String,
    periodRentRenewed: Object,
  },
  data() {
    return {
      urlCheck: "/api/v1/moviment/generate-renewed/check-renewed",
      urlGenerate: "/api/v1/moviment/generate-renewed/generate",
      content: { items: [] },
      endDateHourCurrent: "",
      endDateHourReturn: "",
      loading: true,
      successMoviment: false,
      movimentId: "",
      isInvalid: true,
      periodRenewedRecalculate: false,
      itemSelected: {}
    };
  },
  mounted() {
    this.endDateHourReturn = this.periodRentRenewed.endDateHour;
    this.periodRenewedRecalculate =
      this.userLogged.parameterRental.periodRenewedRecalculate;
    this.checkReturn();
  },
  computed: {
    ...mapGetters("generic", ["showModal"]),
    ...mapState("user", ["userLogged"]),
    ...mapGetters("user", ["hasRule"]),
    ...mapGetters("generateMoviment", ["productSelectedByPeriod"]),
    ...mapState("generateMoviment", ["periodRent", "productRent"]),
  },
  methods: {
    ...mapActions("generic", ["postApi"]),
    ...mapMutations("generic", ["openModal", "removeLoading", "hideModal"]),
    ...mapMutations("generateMoviment", ["cleanProductSelected"]),
    checkReturn() {
      this.loading = true;
      let obj = {
        periodRentCurrent: this.periodRent.fullPeriod,
        periodRentRenewed: this.periodRentRenewed,
        productRent: this.productRent,
        productSelected: this.productSelectedByPeriod,
        periodRenewedRecalculate: this.periodRenewedRecalculate
      };
      let params = { url: this.urlCheck, obj: obj, notNotifyToast: true };
      this.postApi(params).then((response) => {
        if (response.success) {
          this.content = response.content;
          this.isInvalid = false;
        } else {
          this.isInvalid = true;
        }
        this.loading = false;
      });
    },
    generate() {
      this.content.rentId = this.rentId;
      this.loading = true;
      let recalculate = {
        periodRenewedRecalculate: this.periodRenewedRecalculate,
      };

      let params = {
        url: this.urlGenerate,
        obj: { ...this.content, ...recalculate },
        notNotifyToast: false,
      };


      this.postApi(params).then((response) => {
        if (response.success) {
          this.movimentId = response.content;
          let self = this;
          setTimeout(function () {
            self.successMoviment = true;
            self.loading = false;
            self.cleanProductSelected();
            self.removeLoading(["btnGenerateOutput"]);
          }, 300);
        } else {
          this.removeLoading(["btnGenerateOutput"]);
        }
      });
    },
    confirmRenewed() {
      this.openModal("editRenewed");
    },
    editRenewed() {
      this.hideModal();
      this.removeLoading(["btnEndDateHourRenewed"]);
      this.periodRentRenewed.endDateHour = this.endDateHourReturn;
      this.checkReturn();
    },
    recalculate() {
      this.checkReturn();
    },
    teste(item) {
      this.itemSelected = item;
      this.openModal("ItemsRentForRecalculatePrice");
    }
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "timeLineAvailability") {
          this.openModal("timeLineAvailability");
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.table th,
.table td {
  height: 10px !important;
  padding-left: 5px !important;
  padding-top: 7px !important;
  padding-bottom: 5px !important;
  padding-right: 5px !important;
  padding-left: 10px !important;
  border-bottom: 0px !important;
}

.title-header {
  font-size: 14px;
  color: #757d8c;
  font-weight: 400;
  text-transform: uppercase;
}

.td-description {
  max-width: 300px !important;
  word-wrap: break-word !important;
  font-size: 14px;
  color: #757d8c;
  font-style: italic;
}

.td-quantity {
  font-size: 16px;
}

.div-listproducts {
  margin-top: 20px;
}

.div-update {
  margin-left: 15px;
  height: 20px;
}

.edit {
  font-size: 15px;
  color: rgb(112, 112, 112);
  cursor: pointer;
}

.discard {
  font-size: 15px;
  color: red;
  cursor: pointer;
}

.div-recalculate {
  margin-top: 18px;
}

.div-title {
  margin-bottom: 10px;
}

.period {
  font-size: 15px;
}

.title {
  font-size: 15px;
}

.check {
  font-size: 30px;
}

.div-patrimony {
  background-color: #fafafc;
  margin-right: 14%;
}

.status-primary {
  color: blue !important;
  font-size: 20px;
}

.status-success {
  color: rgb(29, 158, 29);
  font-size: 20px;
}

.status-warning {
  color: #f6bb2a;
  font-size: 20px;
}

.status-danger {
  color: red;
  font-size: 20px;
}

.value {
  font-size: 16px;
  font-weight: 400;
}
</style>