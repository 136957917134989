<template>
    <div>
        <div class="div-listproducts">
            <ScrollBar :minHeight="0" :maxHeight="600">
                <div v-for="itemRecalculatePrice in item.itemsRentForRecalculatePrice">
                    <div class="div-item">
                        <div class="div-main">
                            <b-row>
                                <b-col sm="3">
                                    <div class="div-product">
                                        <i class="fas fa-box"></i>
                                        {{ item.productFullName }}
                                    </div>
                                </b-col>
                                <b-col sm="1">
                                    <div class="div-product">
                                        {{ itemRecalculatePrice.quantity }}
                                    </div>
                                </b-col>
                                <b-col sm="2">
                                    <InputDecimal title="Valor Unitário" field="unitValue" :required="true"
                                        :markFormDirty="false" :maxLength="5" type="float"
                                        v-model="itemRecalculatePrice.unitValue" />
                                </b-col>
                                <b-col sm="2">
                                    <InputDecimalDiscount title="Desconto Unitário" field="unitDiscount" :required="false"
                                        _style="color:red;" :maxLength="5" type="float"
                                        v-model="itemRecalculatePrice.unitDiscount" />
                                </b-col>
                                <b-col sm="2">
                                    <InputDecimal title="Seguro Unitário" field="unitInsurance" :required="false"
                                        :markFormDirty="false" :maxLength="5" type="float"
                                        v-model="itemRecalculatePrice.unitInsurance" />
                                </b-col>
                                <b-col sm="2">
                                    <div class="div-total text-center">
                                        <div>Total</div>
                                        {{ itemRecalculatePrice.total | currency }}
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                </div>
            </ScrollBar>
        </div>
        <br>
        <div class="text-right">
            <Button _key="btnEndDateHourReturn" type="success" title="Salvar" classIcon="fa-solid fa-floppy-disk"
                size="medium" :clicked="save" />
        </div>
    </div>
</template>
<script>

import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";
import InputDecimal from "@nixweb/nixloc-ui/src/component/forms/InputDecimal";
import InputDecimalDiscount from "@nixweb/nixloc-ui/src/component/forms/InputDecimalDiscount";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

export default {
    name: "ItemsRentForRecalculatePrice",
    props: {
        item: Object
    },
    components: {
        Molded, ScrollBar, InputDecimal, InputDecimalDiscount, Button
    },
    methods: {
        sum(item) {
            return (item.unitValue + item.unitInsurance - item.unitDiscount) * item.quantity;
        },
        save() {
            alert();
        }
    },
    watch: {
        'item.itemsRentForRecalculatePrice': {
            handler() {
                console.log("alterou");
            },
            deep: true,
        },
    },
}

</script>

<style scoped>
.div-main {
    border: 1px solid #e8eaed;
    background-color: white;
    border-radius: 8px !important;
    padding-top: 10px;
    padding-right: 10px;
}

.div-product {
    margin-left: 15px;
    margin-top: 25px;
    font-size: 16px;
}

.div-item {
    margin-top: 10px;
}

.div-total {
    font-size: 17px;
    margin-top: 5px;
}
</style>