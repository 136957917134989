<template>
  <tr>
    <td class="td-product">
      {{ item.productFullName }}
      <div v-if="item.categoryColor" class="text-center tag-product-category"
        :style="'background-color:' + item.categoryColor">
        <span class="title"> {{ item.categoryName }}</span>
      </div>
      <div class="tag-product-category-no-color text-center" v-else>
        <span class="title"> {{ item.categoryName }}</span>
      </div>
    </td>
    <td class="text-center td-quantity">
      <span class="patrimony" v-if="item.patrimony && !item.allowWithoutPatrimony && manageByPatrimony
        " @click="showPatrimony">
        {{ item.quantity }}
      </span>
      <span v-else> {{ item.quantity }}</span>
    </td>
    <td class="text-center td-quantity">
      {{ totalSelected }}
    </td>
    <td class="text-center">
      <span class="status-primary" v-if="totalSelected > item.quantity">
        <i class="fa-solid fa-rectangle-history-circle-plus"></i>
      </span>
      <span class="status-success" v-if="totalSelected == item.quantity">
        <i class="fa-solid fa-circle-check"></i>
      </span>
      <span class="status-warning" v-if="totalSelected < item.quantity && totalSelected != 0">
        <i class="fa-solid fa-triangle-exclamation"></i>
      </span>
      <span class="status-danger" v-if="totalSelected == 0">
        <i class="fa-solid fa-do-not-enter"></i>
      </span>
    </td>
    <td class="text-right">
      <Button :_key="item.productId + 'a'" type="info" classIcon="fa-solid fa-arrow-right" size="small"
        eventName="transferToSelected" :eventData="item" />
    </td>
  </tr>
</template>
<script>
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import { mapState, mapGetters, mapMutations } from "vuex";

export default {
  name: "ProductRent",
  props: {
    item: Object,
  },
  components: {
    Button,
  },
  computed: {
    ...mapState("generateMoviment", ["productRent"]),
    ...mapState("user", ["userLogged"]),
    ...mapGetters("generateMoviment", ["totalSelectedByProductId"]),
    totalSelected() {
      return this.totalSelectedByProductId(this.item.productId);
    },
    manageByPatrimony() {
      return this.userLogged.parameterStock.manageByPatrimony;
    },
  },
  methods: {
    ...mapMutations("generic", ["addEvent"]),
    showPatrimony() {
      this.addEvent({
        name: "showPatrimony",
        data: this.item.patrimony,
      });
    },
  },
};
</script>
<style scoped>
.td-quantity {
  font-size: 16px;
}

.patrimony {
  color: #3f529b;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}

.patrimony:hover {
  text-decoration: underline;
  transition: 0.1s;
}

.status-primary {
  color: blue;
  font-size: 18px;
}

.status-success {
  color: rgb(29, 158, 29);
  font-size: 18px;
}

.status-warning {
  color: #f6bb2a;
  font-size: 18px;
}

.status-danger {
  color: red;
  font-size: 18px;
}
</style>