<template>
  <div>
    <RentAndSelected v-if="isCheckRenewed == 0" titleProductRent="Locado" titleProductSelected="Renovação"
      :statusPatrimony="2" :rentId="rentId" />
    <div v-show="isCheckRenewed == 1">
      <br />
      <Molded>
        <GeneratePeriodRent :isShowStartDateHour="false" :startDateHour="periodRent.endDateHour"
          v-model="periodRentRenewed" />
      </Molded>
    </div>
    <CheckRenewed :periodRentRenewed="periodRentRenewed" v-if="isCheckRenewed == 2" :rentId="rentId">
      <Button _key="btnClickedBack" type="info" title="Voltar" classIcon="title fa-solid fa-circle-arrow-left"
        size="small" :clicked="backReturn" />
    </CheckRenewed>
    <br />
    <div class="text-center">
      <div class="side-by-side">
        <Button v-if="isCheckRenewed == 1" _key="btnClickedBack" type="info" title="Voltar"
          classIcon="title fa-solid fa-circle-arrow-left" size="small" :clicked="backReturn" />
      </div>
      <div class="side-by-side">
        <Button v-if="isCheckRenewed != 2" _key="btnClickedNext" type="info" title="Avançar" :disabled="isBtnNextDisabled"
          classIcon="title fas fa-arrow-circle-right" size="medium" :clicked="checkRenewed" />
      </div>
    </div>
  </div>
</template>
<script>
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded.vue";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import DateTime from "@nixweb/nixloc-ui/src/component/forms/DateTime";

import RentAndSelected from "../RentAndSelected.vue";
import CheckRenewed from "./CheckRenewed.vue";
import GeneratePeriodRent from "../../../rental/period-rental/GeneratePeriodRent.vue";

import { mapActions, mapState, mapMutations, mapGetters } from "vuex";

export default {
  name: "GenerateRenewed",
  components: {
    RentAndSelected,
    Button,
    Molded,
    DateTime,
    Modal,
    CheckRenewed,
    GeneratePeriodRent,
  },
  props: {
    rentId: String,
  },
  data() {
    return {
      urlGet: "/api/v1/moviment/generate-renewed/get-all",
      urlVerifyDateHourStart:
        "/api/v1/moviment/generate-renewed/verify-date-hour-start",
      periodRentRenewed: {},
      periodRent: {},
      isCheckRenewed: 0,
    };
  },
  computed: {
    ...mapState("generic", ["event"]),
    ...mapGetters("generateMoviment", ["productSelectedByPeriod"]),
    ...mapGetters("generic", ["showModal"]),
    isBtnNextDisabled() {
      if (this.isCheckRenewed == 0) {
        if (this.productSelectedByPeriod.length == 0) {
          return true;
        } else {
          return false;
        }
      }
      if (this.isCheckRenewed == 1) {
        if (this.periodRentRenewed.deliveryDateHour) {
          return false;
        } else {
          return true;
        }
      }
      return true;
    },
  },
  mounted() {
    this.addType("renewed");
  },
  methods: {
    ...mapActions("generic", ["getApi"]),
    ...mapMutations("generic", ["openModal", "removeLoading", "hideModal"]),
    ...mapMutations("generateMoviment", [
      "addType",
      "addPeriodRent",
      "addProductRent",
    ]),
    ...mapMutations("generic", ["addLoading", "removeLoading"]),

    getAll() {
      this.addLoading("movimentProductRent");
      let params = {
        url: this.urlGet,
        obj: { rentId: this.rentId, periodRentId: this.periodRent.id },
      };
      this.getApi(params).then((response) => {
        this.addProductRent(response.content);
      });
      let self = this;
      setTimeout(function () {
        self.removeLoading(["movimentProductRent"]);
      }, 300);
    },
    verifyDateHourStart() {
      let params = {
        url: this.urlVerifyDateHourStart,
        obj: this.periodRent.fullPeriod,
      };
      this.getApi(params).then((response) => {
        this.periodRent = response.content;
      });
      let self = this;
      setTimeout(function () {
        self.removeLoading(["movimentProductRent"]);
      }, 300);
    },
    checkRenewed() {
      this.isCheckRenewed++;
      this.removeLoading(["btnClickedNext"]);
    },
    backReturn() {
      this.isCheckRenewed--;
      this.removeLoading(["btnClickedBack"]);
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "selectedPeriodRent") {
          this.periodRent = event.data.period;
          this.addPeriodRent(event.data.period);
          this.getAll();
          this.verifyDateHourStart();
        }

        if (event.name == "clickedPeriodRentItem") {
          this.openModal("editRenewed");
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.table th,
.table td {
  height: 10px !important;
  padding-left: 5px !important;
  padding-top: 7px !important;
  padding-bottom: 5px !important;
  padding-right: 5px !important;
  padding-left: 10px !important;
  border-bottom: 0px !important;
}

.title-header {
  font-size: 14px;
  color: #757d8c;
  font-weight: 400;
  text-transform: uppercase;
}

.title {
  font-size: 15px;
}

.status-success {
  color: darkgreen;
  font-size: 18px;
}

.status-warning {
  color: #f6bb2a;
  font-size: 18px;
}

.status-danger {
  color: red;
  font-size: 18px;
}
</style>