<template>
  <div>
    <b-row>
      <b-col sm="12">
        <InputText title="Digite o Nº Código e pressione enter ou utilize o Leitor Óptico"
          classIconTitle="fal fa-barcode-read" field="barCode" :maxLength="500" :enter="executeSearch"
          :disabled="disabled" v-model="barCode" />
      </b-col>
    </b-row>
    <Loading type="line" :center="false" v-show="loading" />
    <Alert type="danger" v-show="showAlert && !modal.open">
      <span> {{ message }}</span>
    </Alert>
    <Modal title="Foi encontrado mais de um Produto com o mesmo código!" :width="900" :height="750"
      v-show="showModal('duplicateCode')">
      <Alert type="danger" v-show="showAlert && modal.open">
        <span> {{ message }}</span>
      </Alert>
      <ScrollBar :minHeight="0" :maxHeight="400">
        <div v-for="item in duplicate" :key="item.id">
          <div class="item-duplicate">
            <b-row>
              <b-col sm="8">
                {{ item.productFullName }}
              </b-col>
              <b-col sm="4">
                <div class="text-right">
                  <Button :_key="item.id + 'p'" title="Selecionar" type="info" size="small" :params="item"
                    :clicked="selectProduct" />
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </ScrollBar>
    </Modal>
  </div>
</template>
<script>
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";

import { mapActions, mapMutations, mapState, mapGetters } from "vuex";

export default {
  components: {
    InputText,
    Molded,
    Alert,
    Modal,
    Button,
    ScrollBar,
    Loading,
  },
  name: "SearchByBarcode",
  props: ["urlGet", "disabled", "params", "value"],
  data() {
    return {
      barCode: "",
      showAlert: false,
      message: "",
      duplicate: [],
      loading: false,
    };
  },
  computed: {
    ...mapState("generic", ["modal", "event"]),
    ...mapGetters("generic", ["showModal"]),
  },
  methods: {
    ...mapActions("generic", ["postApi"]),
    ...mapMutations("generic", [
      "addEvent",
      "openModal",
      "hideModal",
      "removeLoading",
    ]),
    executeSearch() {
      this.loading = true;
      let params = {
        url: this.urlGet,
        obj: { barCode: this.barCode, ...this.params },
        notNotifyToast: true,
      };
      this.postApi(params).then((response) => {
        var length = response.content.length;
        if (length == 1) {
          this.addEvent({
            name: "executedSearchByBarcode",
            data: response.content[0],
          });
          this.barCode = "";
        }

        if (length > 1) {
          this.duplicate = response.content;
          this.openModal("duplicateCode");
        }

        if (length == 0) {
          let message = `Produto ${this.barCode} não disponível ou não existe...`;
          this.showNotification(message);
        }
        this.loading = false;
      });
    },
    selectProduct(product) {
      this.addEvent({
        name: "executedSearchByBarcode",
        data: product,
      });
      let id = product.id + "p";

      let self = this;
      setTimeout(function () {
        self.removeLoading([id]);
        self.hideModal();
        self.barCode = "";
      }, 200);
    },
    showNotification(message) {
      this.showAlert = true;
      this.message = message;
      this.hideMessage();
      this.$toasted.show(message, {
        type: "error",
      });
    },
    hideMessage() {
      let self = this;
      setTimeout(function () {
        self.showAlert = false;
      }, 4000);
    },
  },
};
</script>
<style scoped>
.div-bar-code {
  margin-top: 27px;
}

.item-duplicate {
  margin-top: 5px;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 5px;
  background-color: #fafafc !important;
}

.div-search {
  margin-bottom: 10px;
}
</style>