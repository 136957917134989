<template>
  <div>
    <Panel module="Movimentação" title="Gerar Renovação" layout="large" :showFilter="false" :showSearch="false"
      :showButtons="false">
      <div slot="content-main">
        <GenerateRenewed :rentId="rentId" />
      </div>
    </Panel>
  </div>
</template>
<script>

import Panel from "@nixweb/nixloc-ui/src/component/layout/Panel";
import GenerateRenewed from '../../../components/modules/stock/moviment/renewed/GenerateRenewed.vue'

import { mapMutations } from "vuex";

export default {
  name: "GenerateOutputView",
  components: {
    Panel,
    GenerateRenewed
  },
  data() {
    return {
      rentId: this.$route.params.id,
    };
  },
  mounted() {
    this.removeLoading(["panel"]);
  },
  methods: {
    ...mapMutations("generic", ["removeLoading"]),
  },
};
</script>